// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material//ArrowForwardIosSharp';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import MDPBackend from 'services/MDPBackend';
import QAActionButton from './QAActionButton';
import QASourceHistory from './QASourceHistory';
import PdfButton from './PdfButton';
import AddSourceModal from './Modals/AddSourceModal';
import SourceFeedback from './SourceFeedback';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAddingEvidence } from 'features/halOrganization-slice';
import { useLazyGetQAIcdSourcesQuery } from 'api/api';

const muiClasses = {
  accordion: 'bg-jungleMist/20 shadow-accordion rounded'
};

const NLP_BENCHMARK_SOURCE = 'BENCHMARK';
const NLP_HAL_SOURCE = 'HAL';
const NLP_INFERSCIENCE = 'IS_PDF';

type QAIcdSourceCardProps = {
  hcc: Object,
  classes: Object,
  idx: String,
  icdKey: String,
  patientId: String,
  classes: Object,
  isExpanded: Boolean,
  setPdfViwerSrc: Function,
  openHipaaLog: Function,
  setActionToConfirm: Function,
  setInternalNoteToConfirm: Function,
  handleChangeIcdCards: () => void,
  QAHccRefetch: () => void
};

const QAIcdSourceCard = (props: QAIcdSourceCardProps) => {
  const {
    hcc,
    idx,
    icdKey,
    isExpanded,
    handleChangeIcdCards,
    classes,
    patientId,
    setPdfViwerSrc,
    QAHccRefetch
  } = props;

  const [searchParams] = useSearchParams();

  const [paramLoaded, setParamLoaded] = useState(false);

  const { user } = useSelector((state) => state.user);
  const currentNlpSource = useSelector(
    (state) => state.nlpSource.currentNlpSource
  );
  const [getQAIcdSources, { isLoading }] = useLazyGetQAIcdSourcesQuery();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState({});
  const [openSourceModal, setOpenSourceModal] = useState(false);
  const cardEl = useRef(null);
  const sourceEl = useRef(null);
  const encodeFactName = useCallback((str) => encodeURIComponent(str), []);
  const scrollToRef = useCallback(() => {
    const rect = cardEl.current?.getBoundingClientRect();
    if (rect) {
      window.scrollTo({
        top: rect.top + window.scrollY,
        behavior: 'smooth'
      });
    }
  }, [cardEl]);

  const getSourceGroupKey = (source) => {
    const annotation = source?.annotations?.Annotation?.toLowerCase() || '';
    const factName = source?.factName?.toLowerCase() || '';
    const factValue = source?.factValue?.toLowerCase() || '';

    if (annotation === 'diagnostics' || annotation === 'medication') {
      return `${annotation}__${factName}`;
    } else if (annotation === 'mention' || annotation === 'disease') {
      return `${annotation}__${factValue}`;
    }

    return `${annotation}__${factName}__${factValue}`;
  };

  // Add this function to organize sources into groups
  const organizeSourcesIntoGroups = (sourcesData) => {
    const groupedSources = {};

    // Iterate through all sources and group them
    Object.entries(sourcesData).forEach(([key, sourceData]) => {
      const recentSource = sourceData.recentSource;
      if (!recentSource) return;

      const groupKey = getSourceGroupKey(recentSource);

      if (!groupedSources[groupKey]) {
        groupedSources[groupKey] = {
          recentSource,
          sourceHistoryCount: sourceData.sourceHistoryCount,
          // Use the first non-empty value as the display name
          displayName:
            recentSource.factName ||
            (recentSource.factValue
              ? `${recentSource.factValue}`
              : 'Source has no name or value')
        };
      } else {
        // Increment history count for duplicate sources
        groupedSources[groupKey].sourceHistoryCount +=
          sourceData.sourceHistoryCount + 1;
      }
    });

    return groupedSources;
  };

  const getFactValue = (source) => {
    if (
      source.factValue &&
      source.factValue.trim().toLowerCase() === 'undefined'
    ) {
      return '';
    }

    if (source.annotations.Annotation === 'Medication') {
      return source.factValue
        ? `${source.factValue} ${source.unit ?? ''}`
        : 'Supportive Evidence: Yes';
    }

    if (source.annotations.Annotation === 'Diagnostics') {
      return source.factValue
        ? `${source.factValue} ${source.unit ?? ''}`
        : 'Supportive Evidence: Yes';
    }

    return 'Supportive Evidence: Yes';
  };

  const getQASources = async () => {
    setLoading(true);

    try {
      const qaSources = await getQAIcdSources({
        patientId,
        hccId: hcc._id
      }).unwrap();

      console.log('[getQASources] qaSources: ', qaSources);

      setSources(qaSources);
    } catch (error) {
      console.log('[getQASources] error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hccId = searchParams.get('hcc_id');
    if (!paramLoaded && hccId === hcc._id && cardEl.current) {
      scrollToRef();
      cardEl.current?.click();
    }
    setParamLoaded(true);
  }, [hcc, cardEl, scrollToRef, searchParams, paramLoaded]);

  const createTags = (tags) => {
    if (!tags || !tags.length) return;
    const chips = tags.map((tag, index) => {
      const chip = (
        <Chip
          key={`${tag.text}${index}`}
          label={tag.text}
          variant="outlined"
          size="medium"
          style={{ color: tag.color, borderColor: tag.color }}
        />
      );

      if (!tag.tooltip) {
        return chip;
      }

      return (
        <Tooltip
          key={tag.text + index}
          arrow
          placement="top"
          title={tag.tooltip}
        >
          {chip}
        </Tooltip>
      );
    });

    return (
      <Stack direction="row" spacing={1}>
        {chips}
      </Stack>
    );
  };

  return (
    <Accordion
      key={idx}
      className={`[&.Mui-expanded]:${muiClasses.accordion} 
      [&.Mui-collapsed]:${muiClasses.accordion} ${classes.icdAccordionWrapper}`}
      style={{ zIndex: '1' }}
      expanded={isExpanded}
      onChange={(e, expanded) => {
        handleChangeIcdCards(icdKey)();
        if (expanded) getQASources();
      }}
    >
      <AccordionSummary
        expandIcon={user?.role !== 'payer' && <ExpandMoreIcon fontSize="10" />}
        aria-controls={`content-${idx}`}
        id={`header-${idx}`}
        ref={cardEl}
        className="!min-h-[60px] !py-1 !px-2 flex-row-reverse 
        [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-90"
      >
        <div className="flex items-center w-full">
          <div className="font-extrabold text-secondary-main w-[70px] ml-[10px]">
            {hcc.icdCode}
          </div>
          <span className="font-semibold break-normal mx-[20px]">
            {hcc.icdCodeDescription}
          </span>
          {createTags(hcc.tags)}
        </div>
        <QAActionButton
          hcc={hcc}
          classes={classes}
          slug={'confirm'}
          patientId={patientId}
          QAHccRefetch={QAHccRefetch}
        />
        <QAActionButton
          hcc={hcc}
          classes={classes}
          slug={'deny'}
          patientId={patientId}
          QAHccRefetch={QAHccRefetch}
        />
      </AccordionSummary>
      <AccordionDetails
        className="py-4 px-2 !border-0 block bg-white"
        style={{
          padding: '1.5rem',
          borderTop: '1px solid rgba(0, 0, 0, .125)'
        }}
      >
        <div
          className={`flex items-center justify-between w-full ${
            hcc.reason ? 'mt-2 mb-4' : ''
          } `}
        >
          <div>
            {hcc.reason ? (
              <>
                <Typography className={classes.reason}>Reason:</Typography>
                <Typography className={classes.reasonStr}>
                  {hcc.reason}
                </Typography>
              </>
            ) : null}
            {hcc.confidenceScore ? (
              <div className="flex items-center mt-2 mb-4 justify-left">
                <div className="mr-1">
                  <Typography className={classes.confidenceScore}>
                    Confidence Score:
                  </Typography>
                </div>
                <Typography className={classes.confidenceScoreStr}>
                  {parseFloat(hcc.confidenceScore).toFixed(3)}
                </Typography>
              </div>
            ) : null}
          </div>
          {hcc.reason && (
            <div className={classes.lastUpdatedWrapper}>
              <span style={{ fontSize: '1rem' }}>
                {hcc.lastUpdatedFormatted}
              </span>
              <br />
              <span className={classes.lastUpdatedDt}>Last updated</span>
            </div>
          )}
        </div>
        <div className={classes.sourcesWrapper}>
          <div className={classes.sources} ref={sourceEl}>
            {loading ? (
              <CircularProgress style={{ marginTop: 20 }} size={25} />
            ) : sources && Object.keys(sources).length ? (
              Object.entries(organizeSourcesIntoGroups(sources)).map(
                ([groupKey, sourceData], j) => {
                  const { recentSource, sourceHistoryCount, displayName } =
                    sourceData;
                  const sourceKey = `source-${groupKey}-${j}`;

                  return (
                    <div
                      key={sourceKey}
                      className={classes.sourcesSection}
                      id={`${encodeFactName(groupKey)}`}
                    >
                      <Typography className={classes.reason}>
                        {displayName}
                      </Typography>

                      {recentSource ? (
                        <div className={classes.sourceCard}>
                          <Grid key={sourceKey} container className="!w-full">
                            <Grid item sm={12} md={10} className="!w-full">
                              {recentSource.date ? (
                                <div className={classes.dateWrapper}>
                                  <Typography className={classes.date}>
                                    {recentSource.date}
                                  </Typography>
                                </div>
                              ) : null}

                              <div className={classes.factValueWrapper}>
                                <Typography className={classes.factValue}>
                                  {getFactValue(recentSource)}
                                </Typography>
                              </div>

                              {recentSource.excerpt ? (
                                <div className={classes.excerpt}>
                                  <FontAwesomeIcon
                                    style={{ marginRight: 5, color: 'grey' }}
                                    icon={solid('quote-left')}
                                  />
                                  <Typography>
                                    {recentSource.excerpt}
                                  </Typography>
                                </div>
                              ) : null}

                              {recentSource.nlpSource ? (
                                <div className={classes.annotations}>
                                  <TextField
                                    size="small"
                                    sx={{
                                      '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000',
                                        fontSize: '0.9rem!important',
                                        background: 'rgba(51, 122, 94, 0.05)',
                                        textAlign: 'left'
                                      }
                                    }}
                                    className={classes.annotation}
                                    label={`NLP`}
                                    value={recentSource.nlpSource}
                                    disabled
                                  />
                                  <TextField
                                    size="small"
                                    sx={{
                                      '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000',
                                        fontSize: '0.9rem!important',
                                        background: 'rgba(51, 122, 94, 0.05)',
                                        textAlign: 'left'
                                      }
                                    }}
                                    className={classes.annotation}
                                    label={`QA`}
                                    value={recentSource.qa}
                                    disabled
                                  />
                                </div>
                              ) : null}

                              {recentSource.annotations ? (
                                <div className={classes.annotations}>
                                  {Object.keys(recentSource.annotations).map(
                                    (annotationName) => (
                                      <div
                                        key={`${annotationName}${sourceKey}`}
                                      >
                                        {recentSource.annotations[
                                          annotationName
                                        ] ? (
                                          <TextField
                                            size="small"
                                            sx={{
                                              '& .MuiInputBase-input.Mui-disabled':
                                                {
                                                  WebkitTextFillColor:
                                                    '#000000',
                                                  fontSize: '0.9rem!important',
                                                  background:
                                                    'rgba(51, 122, 94, 0.05)',
                                                  textAlign: 'left'
                                                }
                                            }}
                                            style={{
                                              width:
                                                recentSource.annotations[
                                                  annotationName
                                                ].length *
                                                  6 +
                                                70
                                            }}
                                            className={classes.annotation}
                                            label={annotationName}
                                            value={
                                              recentSource.annotations[
                                                annotationName
                                              ]
                                            }
                                            disabled
                                          />
                                        ) : null}
                                      </div>
                                    )
                                  )}
                                  {recentSource.sourceLocation?.section ? (
                                    <div className={classes.annotation}>
                                      <TextField
                                        size="small"
                                        sx={{
                                          '& .MuiInputBase-input.Mui-disabled':
                                            {
                                              WebkitTextFillColor: '#000000',
                                              fontSize: '0.9rem!important',
                                              background:
                                                'rgba(51, 122, 94, 0.05)',
                                              textAlign: 'left'
                                            },
                                          width:
                                            recentSource.sourceLocation.section
                                              .length *
                                              6 +
                                            70
                                        }}
                                        className={classes.annotation}
                                        label="Section"
                                        value={
                                          recentSource.sourceLocation.section
                                        }
                                        disabled
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {recentSource.links?.length ? (
                                <div style={{ marginTop: '1rem' }}>
                                  <div style={{ display: 'inline-flex' }}>
                                    {recentSource.links.map((src, k) =>
                                      src.label === 'PDF' ? (
                                        <PdfButton
                                          key={k}
                                          hccId={hcc._id}
                                          id={encodeFactName(groupKey)}
                                          onClick={() => {
                                            setPdfViwerSrc(recentSource);
                                          }}
                                          label={src.label}
                                        />
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          key={k}
                                          className={classes.externalLinkBtn}
                                          href={src.url}
                                          target="_blank"
                                        >
                                          {src.label}
                                          <ForwardRef>
                                            <FontAwesomeIcon
                                              icon={solid('external-link')}
                                              className={classes.icon}
                                            />
                                          </ForwardRef>
                                        </Button>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </Grid>

                            {user?.isReveleerTeam && (
                              <Grid item sm={12} md={2} className="!w-full">
                                <SourceFeedback
                                  userFeedback={recentSource.userFeedback}
                                  sourceId={recentSource.id}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      ) : null}

                      {sourceHistoryCount > 0 ? (
                        <QASourceHistory
                          sourceHistoryCount={sourceHistoryCount}
                          nlpSource={recentSource?.nlpSource}
                          factName={groupKey}
                          hcc={hcc}
                          patientId={patientId}
                          classes={classes}
                          setPdfViwerSrc={setPdfViwerSrc}
                        />
                      ) : null}
                    </div>
                  );
                }
              )
            ) : (
              <div>No Sources found.</div>
            )}
          </div>

          {/* <div className={classes.sources} ref={sourceEl}>
            {currentNlpSource === NLP_INFERSCIENCE &&
              (loading ? (
                <CircularProgress style={{ marginTop: 20 }} size={25} />
              ) : sources && Object.keys(sources).length ? (
                Object.keys(sources).map((factName, j) => {
                  const recentSource = sources[factName].recentSource;
                  const sourceHistoryCount =
                    sources[factName].sourceHistoryCount;
                  const sourceKey = `source-${factName}-${j}`;
                  return (
                    <div
                      key={sourceKey}
                      className={classes.sourcesSection}
                      id={`${encodeFactName(factName)}`}
                    >
                      <Typography className={classes.reason}>
                        {factName}
                      </Typography>

                      {recentSource ? (
                        <div className={classes.sourceCard}>
                          <Grid key={sourceKey} container className="!w-full">
                            <Grid item sm={12} md={10} className="!w-full">
                              {recentSource.date ? (
                                <div className={classes.dateWrapper}>
                                  <Typography className={classes.date}>
                                    {recentSource.date}
                                  </Typography>
                                </div>
                              ) : null}
                              {recentSource.factValue ? (
                                <div className={classes.factValueWrapper}>
                                  <Typography className={classes.factValue}>
                                    {currentNlpSource === 'HAL' ||
                                    currentNlpSource === 'BENCHMARK'
                                      ? `${recentSource.factValue}`
                                      : `Supportive Evidence: ${recentSource.factValue}`}
                                  </Typography>
                                </div>
                              ) : null}
                              {recentSource.excerpt ? (
                                <div className={classes.excerpt}>
                                  <FontAwesomeIcon
                                    style={{ marginRight: 5, color: 'grey' }}
                                    icon={solid('quote-left')}
                                  />
                                  <Typography>
                                    {recentSource.excerpt}
                                  </Typography>
                                </div>
                              ) : null}

                              {recentSource.annotations ? (
                                <div className={classes.annotations}>
                                  {Object.keys(recentSource.annotations).map(
                                    (annotationName) => (
                                      <div
                                        key={`${annotationName}${sourceKey}`}
                                      >
                                        {recentSource.annotations[
                                          annotationName
                                        ] ? (
                                          <TextField
                                            size="small"
                                            sx={{
                                              '& .MuiInputBase-input.Mui-disabled':
                                                {
                                                  WebkitTextFillColor:
                                                    '#000000',
                                                  fontSize: '0.9rem!important',
                                                  background:
                                                    'rgba(51, 122, 94, 0.05)',
                                                  textAlign: 'left'
                                                }
                                            }}
                                            style={{
                                              width:
                                                recentSource.annotations[
                                                  annotationName
                                                ].length *
                                                  6 +
                                                70
                                            }}
                                            className={classes.annotation}
                                            label={annotationName}
                                            value={
                                              recentSource.annotations[
                                                annotationName
                                              ]
                                            }
                                            disabled
                                          />
                                        ) : null}
                                      </div>
                                    )
                                  )}
                                  {recentSource.sourceLocation.section ? (
                                    <div className={classes.annotation}>
                                      <TextField
                                        size="small"
                                        sx={{
                                          '& .MuiInputBase-input.Mui-disabled':
                                            {
                                              WebkitTextFillColor: '#000000',
                                              fontSize: '0.9rem!important',
                                              background:
                                                'rgba(51, 122, 94, 0.05)',
                                              textAlign: 'left'
                                            },
                                          width:
                                            recentSource.sourceLocation.section
                                              .length *
                                              6 +
                                            70
                                        }}
                                        className={classes.annotation}
                                        label={`Section`}
                                        value={
                                          recentSource.sourceLocation.section
                                        }
                                        disabled
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {recentSource.links.length ? (
                                <div style={{ marginTop: '1rem' }}>
                                  <div style={{ display: 'inline-flex' }}>
                                    {recentSource.links.map((src, k) =>
                                      src.label === 'PDF' ? (
                                        <PdfButton
                                          key={k}
                                          hccId={hcc._id}
                                          id={encodeFactName(factName)}
                                          onClick={() => {
                                            setPdfViwerSrc(recentSource);
                                          }}
                                          label={src.label}
                                        />
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          key={k}
                                          className={classes.externalLinkBtn}
                                          href={src.url}
                                          target="_blank"
                                        >
                                          {src.label}
                                          <ForwardRef>
                                            <FontAwesomeIcon
                                              icon={solid('external-link')}
                                              className={classes.icon}
                                            />
                                          </ForwardRef>
                                        </Button>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </Grid>

                            {user?.isReveleerTeam && (
                              <Grid item sm={12} md={2} className="!w-full">
                                <SourceFeedback
                                  userFeedback={recentSource.userFeedback}
                                  sourceId={recentSource.id}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      ) : null}

                      {sourceHistoryCount > 0 ? (
                        <SourceHistory
                          sourceHistoryCount={sourceHistoryCount}
                          factName={factName}
                          hcc={hcc}
                          patientId={patientId}
                          classes={classes}
                          setPdfViwerSrc={setPdfViwerSrc}
                        />
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <div>No Sources found.</div>
              ))}
          </div> */}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default QAIcdSourceCard;
