// @flow

import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material//ArrowForwardIosSharp';
import QAIcdSourceCard from './QAIcdSourceCard';
import { useSelector } from 'react-redux';

type QAHccSourceCardProps = {
  patientHccs: Array<Object>,
  hccLabel: String,
  _key: String,
  patientId: String,
  hcc: Object,
  classes: Object,
  setActionToConfirm: Function,
  setInternalNoteToConfirm: Function,
  openHipaaLog: Function,
  setPdfViwerSrc: Function,
  QAHccRefetch: () => void
};

const QAHccSourceCard = (props: QAHccSourceCardProps) => {
  const {
    patientHccs,
    hccLabel,
    _key,
    patientId,
    classes,
    setActionToConfirm,
    setInternalNoteToConfirm,
    setPdfViwerSrc,
    openHipaaLog,
    QAHccRefetch
  } = props;

  const { user } = useSelector((state) => state.user);

  const [expandedHccCards, setExpandedHccCards] = useState({});

  useEffect(() => {
    setExpandedHccCards({});
  }, [patientHccs]);

  const handleChangeHccCards = (panel) => () => {
    setExpandedHccCards({
      ...expandedHccCards,
      [panel]:
        typeof expandedHccCards[panel] === 'boolean'
          ? !expandedHccCards[panel]
          : false
    });
  };

  const [expandedIcdCards, setExpandedIcdCards] = useState({});
  const handleChangeIcdCards = (panel) => () => {
    const _expandedIcdCards = { ...expandedIcdCards };
    _expandedIcdCards[panel] = !_expandedIcdCards[panel];
    setExpandedIcdCards(_expandedIcdCards);
  };
  const accordionClassName =
    '[&.Mui-expanded]:rounded [&.MuiPaper-root]:shadow-none [&.MuiButtonBase-root]:border-0';
  const accordionSummaryClassName =
    'py-1 px-2 flex-row-reverse [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-90';

  return (
    <Accordion
      className={accordionClassName}
      disabled={!patientHccs?.length}
      style={{ zIndex: '1' }}
      expanded={expandedHccCards[_key]}
      defaultExpanded={true}
      onChange={handleChangeHccCards(_key)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="10" />}
        aria-controls={`content-${hccLabel}`}
        id={`header-${hccLabel}`}
        className={accordionSummaryClassName}
      >
        <div className={classes.hccLabel}>{hccLabel} </div>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionBody}
        style={{
          padding: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, .125)'
        }}
      >
        {patientHccs.map((hcc) => (
          <QAIcdSourceCard
            key={hcc.icdCode}
            idx={hcc.icdCode}
            icdKey={`${_key}-${hcc.icdCode}`}
            classes={classes}
            hcc={hcc}
            patientId={patientId}
            setPdfViwerSrc={setPdfViwerSrc}
            handleChangeIcdCards={handleChangeIcdCards}
            QAHccRefetch={QAHccRefetch}
            isExpanded={
              (expandedIcdCards[`${_key}-${hcc.icdCode}`] || false) &&
              user?.role !== 'payer'
            }
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default QAHccSourceCard;
