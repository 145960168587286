// @flow
import React, { useState, Suspense } from 'react';
import { Auth } from 'aws-amplify';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useNavigate
} from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import CircularProgress from '@mui/material/CircularProgress';
import { HOME, LOGIN } from 'settings/constants';
import InvalidUrl from './InvalidUrl';
import PrivateRoute from './PrivateRoute';
import routes from './Routes';
import HCCPage from 'containers/Patient/pages/HCC';
import FileBrowserPage from 'containers/Patient/pages/FileBrowser';
import { useSelector } from 'react-redux';
import CareGaps from 'containers/Patient/pages/CareGap/CareGaps';
import { sanitizeRedirectOrURL } from 'utils/jsUtils';

const RouterContent = (): React.Node => {
  const [forceLogout, setForceLogout] = useState(false);
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const onIdle = () => {
    const currentPath = window.location.pathname;
    if (!currentPath.startsWith('/login')) {
      const sanitizedURL = sanitizeRedirectOrURL(window.location.href);
      Auth.signOut().then(() => {
        setForceLogout(true);
        navigate(`/login?redirect=${encodeURIComponent(sanitizedURL)}`);
      });
    }
  };

  const timeoutDuration = 15 * 60 * 1000; // 15 minutes

  useIdleTimer({
    timeout: timeoutDuration,
    onIdle,
    events: [
      'mousemove',
      'mousedown',
      'keydown',
      'touchstart',
      'wheel',
      'scroll'
    ],
    crossTab: false,
    debounce: 500
  });

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="*" element={<InvalidUrl />} />
        {routes.map((route, i) => {
          const Component = route.component;

          return route.private ? (
            route.path === '/patient/:patientId' ? (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                element={
                  <PrivateRoute
                    component={route.component}
                    forceLogout={forceLogout}
                  />
                }
              >
                <Route exact path="" element={<HCCPage />} />
                {user?.role !== 'payer' && (
                  <Route exact path="files" element={<FileBrowserPage />} />
                )}
                <Route exact path="care-gaps" element={<CareGaps />} />
              </Route>
            ) : (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                element={
                  <PrivateRoute
                    component={route.component}
                    forceLogout={forceLogout}
                  />
                }
              />
            )
          ) : (
            <Route
              element={<Component />}
              exact={route.exact}
              path={route.path}
              key={i}
            />
          );
        })}
        <Route
          exact
          path={HOME}
          element={
            <Navigate
              to={`/login?redirect=${encodeURIComponent(
                sanitizeRedirectOrURL(window.location.href)
              )}`}
            />
          }
        />
      </Routes>
    </Suspense>
  );
};

const RouterApp = () => (
  <Router>
    <RouterContent />
  </Router>
);

export default RouterApp;
