// @flow

import React, { useEffect, useState } from 'react';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlined from '@mui/icons-material/ThumbDownOutlined';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useUpdateSourceFeedbackMutation } from 'api/api';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';
import { CircularProgress } from '@mui/material';

const reasons = [
  'Clinical indicator of this evidence does not validate the clinical formula for this ICD',

  'Source duplicate',

  'Excerpt does not support the Clinical Indicator of this Evidence',

  'Supportive evidence not found within source data',

  'Supportive evidence is semantically close to ICD suggestion, but incorrect',

  'Supportive evidence is from part of formula, but missing essential details',

  'Supportive evidence points to a negative finding',

  'Supportive evidence is from an inactive or resolved problem',

  'Supportive evidence is from inappropriate sections of the record (i.e., Family History, Patient Education)',

  'Supportive evidence is too specific',

  'Supportive evidence is too broad',

  'Incorrect clinical formula',

  'Supportive evidence refers to someone other than the patient'
];

type Props = {
  sourceId: String,
  userFeedback: Object
};

const SourceFeedback = ({ sourceId, userFeedback = {} }: Props) => {
  const { agree: agreeParam, reason: reasonParam = '' } = userFeedback;
  const dispatch = useDispatch();
  const [updateSourceFeedback, { isLoading }] =
    useUpdateSourceFeedbackMutation();
  const [agree, setAgree] = useState(agreeParam);
  const [selectedReason, setSelectedReason] = useState(reasonParam);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAgree = () => {
    const agreeValue = agree === true ? null : true;
    handleSubmit(agreeValue, '');
  };

  const handleDisagree = (e) => {
    if (agree === false) {
      setAgree(null);
      handleSubmit(null, '');
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleSubmit = (agreeVal, reason) => {
    updateSourceFeedback({ sourceId, agree: agreeVal, reason })
      .unwrap()
      .then((success) => {
        if (success) {
          setAgree(agreeVal);
          setSelectedReason(reason);
          dispatch(
            showToastMsg({
              open: true,
              message: 'Feedback updated successfully',
              level: 'success',
              duration: 5000
            })
          );
        } else {
          throw new Error(success);
        }
      })
      .catch((error) => {
        dispatch(
          showToastMsg({
            open: true,
            message: error?.message ?? 'update failed',
            level: 'error',
            duration: 5000
          })
        );
      });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReason = (reasonVal) => {
    setAnchorEl(null);
    handleSubmit(false, reasonVal);
    setSelectedReason(reasonVal);
  };

  useEffect(() => {
    if (agreeParam || reasonParam) {
      console.log('feedback', userFeedback);

      setAgree(agreeParam);
      setSelectedReason(reasonParam);
    }
  }, [agreeParam, reasonParam]);

  return (
    <div className="w-full">
      <Stack direction="row" spacing={0} justifyContent="end">
        {agree !== false && (
          <IconButton className="!text-mineShaft/40" onClick={handleAgree}>
            {agree ? (
              <ThumbUp color={isLoading ? 'disabled' : 'success'} />
            ) : (
              <ThumbUpOutlined />
            )}
          </IconButton>
        )}
        {agree !== true && (
          <IconButton className="!text-mineShaft/40" onClick={handleDisagree}>
            {agree === false && selectedReason ? (
              <ThumbDown color={isLoading ? 'disabled' : 'error'} />
            ) : (
              <ThumbDownOutlined />
            )}
          </IconButton>
        )}
      </Stack>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && agree !== false}
        onClose={() => handleMenuClose()}
      >
        {reasons.map((reasonItem) => (
          <MenuItem
            key={`${reasonItem}`}
            onClick={() => handleReason(reasonItem)}
          >
            {reasonItem}
          </MenuItem>
        ))}
      </Menu>
      {isLoading && (
        <div className="flex justify-end w-full p-1">
          <CircularProgress size={20} />
        </div>
      )}

      {selectedReason && !isLoading && (
        <div className="mt-1 text-right text-[#d32f2f] pr-1">
          {selectedReason}
        </div>
      )}
    </div>
  );
};

export default SourceFeedback;
