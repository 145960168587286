// @flow

import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@mui/material/CircularProgress';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import { WORKFLOW_ACTION_SLUG_SUFFIXES, getButtonClass } from './PatientHCCs';
import { useConfirmQAIcdMutation, useDenyQAIcdMutation } from 'api/api';
import { useDispatch } from 'react-redux';
import { deleteIcdCode } from 'features/qa-slice';

type QAActionButtonProps = {
  hcc: Object,
  classes: Object,
  slug: String,
  patientId: String,
  QAHccRefetch: () => void
};

const QAActionButton = (props: QAActionButtonProps) => {
  const { hcc, classes, slug, patientId, QAHccRefetch } = props;

  type QACustomMenuItemProps = {
    label: String,
    slug: String,
    hcc: Object,
    patientId: String,
    QAHccRefetch: () => void
  };

  const QACustomMenuItem = (props: QACustomMenuItemProps) => {
    const { slug, label, hcc, patientId, QAHccRefetch } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [confirmQAIcd, { isLoading: confirmQAIcdLoading }] =
      useConfirmQAIcdMutation();
    const [denyQAIcd, { isLoading: denyQAIcdLoading }] = useDenyQAIcdMutation();

    const [openDialog, setOpenDialog] = useState(false);
    const [denyReason, setDenyReason] = useState('');

    const dispatch = useDispatch();

    const hccId = hcc._id;
    const hccLabel = `HCC ${hcc.hccCategory} (${hcc.hccCategoryDescription})`;
    const handleAction = async () => {
      try {
        setIsLoading(true);
        if (slug === 'confirm') {
          console.log(`confirm with hcc label: ${hccLabel}`);
          console.log(`removing code with id: ${hccId}`);
          dispatch(deleteIcdCode({ hccLabel, icdCodeId: hccId }));
          await confirmQAIcd({ patientId, hccId }).unwrap();
          setTimeout(() => QAHccRefetch(), 500);
        } else if (slug === 'deny') {
          setOpenDialog(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleDeny = async () => {
      try {
        setIsLoading(true);
        console.log(`deny with hcc label: ${hccLabel}`);
        console.log(`removing code with id: ${hccId}`);
        dispatch(deleteIcdCode({ hccLabel, icdCodeId: hccId }));
        await denyQAIcd({ patientId, hccId, reason: denyReason }).unwrap();
        setTimeout(() => QAHccRefetch(), 500);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        setOpenDialog(false);
      }
    };

    return (
      <>
        <Button
          key={slug}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAction();
          }}
          disabled={isLoading}
          className={`${classes.actionBtn} ${getButtonClass(slug, classes)}`}
        >
          {isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : slug === 'confirm' ? (
            'Send to Customer'
          ) : (
            'Reject'
          )}
        </Button>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Deny Reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide a reason for denying this code.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Reason"
              type="text"
              fullWidth
              value={denyReason}
              onChange={(e) => setDenyReason(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeny} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <div className="relative flex items-center z-9991">
      <QACustomMenuItem
        hcc={hcc}
        slug={slug}
        patientId={patientId}
        QAHccRefetch={QAHccRefetch}
      />
    </div>
  );
};

export default QAActionButton;
