// @flow

import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material//ArrowForwardIosSharp';
import QASourceHistoryList from './QASourceHistoryList';
import { useSelector } from 'react-redux';

type QASourceHistoryProps = {
  sourceHistoryCount: Number,
  factName: String,
  nlpSource: String,
  hcc: String,
  patientId: String,
  classes: Object,
  setPdfViwerSrc: Function
};

const QASourceHistory = (props: QASourceHistoryProps) => {
  const {
    sourceHistoryCount,
    factName,
    nlpSource,
    hcc,
    classes,
    setPdfViwerSrc,
    patientId
  } = props;
  const [show, setShow] = useState(false);

  const formatFactName = (factName) => {
    const [annotation, name, value] = factName.split('__');
    const parts = [annotation, name, value]
      .filter(Boolean)
      .filter((part) => part.trim().toLowerCase() !== 'undefined')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1)); // Capitalize first letter
    return parts.join(' | ');
  };

  return (
    <div>
      <div className={classes.sourceHistoryBtn} onClick={() => setShow(!show)}>
        <ExpandMoreIcon
          fontSize="10"
          style={{
            transform: show ? 'rotate(90deg)' : 'rotate(0)',
            marginRight: 5
          }}
        />{' '}
        {show ? 'Hide' : 'Reveal'} {sourceHistoryCount} other{' '}
        {sourceHistoryCount === 1 ? 'source' : 'sources'} for{' '}
        {formatFactName(factName)}
      </div>
      <div className={classes.sourceHistory}>
        {show ? (
          <QASourceHistoryList
            factName={factName}
            nlpSource={nlpSource}
            hccId={hcc._id}
            patientId={patientId}
            classes={classes}
            setPdfViwerSrc={setPdfViwerSrc}
          />
        ) : null}
      </div>
    </div>
  );
};

export default QASourceHistory;
