// @flow

import React, { useState } from 'react';
import { format, parseISO, isValid, startOfYear } from 'date-fns';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import enLocale from 'date-fns/locale/en-US';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type Props = {
  loading: Boolean,
  nlpSources: Array<Object>,
  searchParams: Object,
  setSearchParams: Function
};

const MIN_DATE = new Date('1970-01-01');
const MAX_DATE = new Date();

const styles = {
  searchPeriodDatePicker: 'w-full bg-white border-[#9e9e9e] rounded-sm',
  searchPeriodFilter: 'w-full bg-white border-[#9e9e9e] rounded-sm'
};

export const dates = {
  all: {
    from: '',
    to: ''
  },
  thisYear: {
    from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  '2021ToDate': {
    from: format(startOfYear(new Date('2021-01-01')), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  '2020ToDate': {
    from: format(startOfYear(new Date('2020-01-01')), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  '2019ToDate': {
    from: format(startOfYear(new Date('2019-01-01')), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  }
};

export const searchPeriods = [
  {
    name: 'All',
    value: 'all'
  },
  {
    name: 'This year',
    value: 'thisYear'
  },
  {
    name: '2021 to date',
    value: '2021ToDate'
  },
  {
    name: '2020 to date',
    value: '2020ToDate'
  },
  {
    name: '2019 to date',
    value: '2019ToDate'
  }
];

const HCCFilter = (props: Props) => {
  const { loading, nlpSources, searchParams, setSearchParams } = props;
  const [evidenceFromValid, setEvidenceFromValid] = useState(true);
  const [evidenceToValid, setEvidenceToValid] = useState(true);

  const setDatesBasedOnPeriodName = (periodName) => {
    setSearchParams(
      (prev) => {
        prev.set('searchPeriod', periodName);

        return prev;
      },
      { replace: true }
    );
    const selectedPeriod = searchPeriods.find(
      (searchPeriod) => searchPeriod.name === periodName
    );
    if (!selectedPeriod) return;
    setSearchParams(
      (prev) => {
        prev.set('evidenceFrom', dates[selectedPeriod.value].from);
        prev.set('evidenceTo', dates[selectedPeriod.value].to);

        return prev;
      },
      { replace: true }
    );
  };

  const isValidDate = (date) => {
    return isValid(date) && date > MIN_DATE && date < MAX_DATE;
  };

  const updatePeriodDate = (field, fieldValidSetter, newDate) => {
    const isValidNewDate = isValidDate(newDate);
    fieldValidSetter(isValidNewDate);
    if (!isValidNewDate) return;
    setSearchParams(
      (prev) => {
        prev.set(field, format(newDate, 'yyyy-MM-dd'));
        prev.set('searchPeriod', '');

        return prev;
      },
      { replace: true }
    );
  };

  const updateNlpSource = (nlpSource) => {
    setSearchParams(
      (prev) => {
        prev.set('nlpSource', nlpSource);

        return prev;
      },
      { replace: true }
    );
  };

  const handleResetFilters = () => {
    setSearchParams(
      (prev) => {
        prev.set('evidenceFrom', '');
        prev.set('evidenceTo', '');
        prev.set('searchPeriod', '');
        prev.set('nlpSource', '');

        return prev;
      },
      { replace: true }
    );
  };

  return (
    <div className="mb-2.5">
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={6} md={3}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enLocale}
          >
            <DatePicker
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              disableFuture
              label="From source date"
              value={
                searchParams.get('evidenceFrom')
                  ? parseISO(searchParams.get('evidenceFrom'))
                  : null
              }
              onChange={(newEvidenceFrom) =>
                updatePeriodDate(
                  'evidenceFrom',
                  setEvidenceFromValid,
                  newEvidenceFrom
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="outlined"
                  error={!evidenceFromValid}
                  className={styles.searchPeriodDatePicker}
                />
              )}
              disabled={loading}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={6} md={3}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enLocale}
          >
            <DatePicker
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              label="Until source date"
              value={
                searchParams.get('evidenceTo')
                  ? parseISO(searchParams.get('evidenceTo'))
                  : null
              }
              onChange={(newEvidenceTo) =>
                updatePeriodDate(
                  'evidenceTo',
                  setEvidenceToValid,
                  newEvidenceTo
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="outlined"
                  error={!evidenceToValid}
                  className={styles.searchPeriodDatePicker}
                />
              )}
              disabled={loading}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={6} md={3}>
          <FormControl className={styles.searchPeriodFilter} disabled={loading}>
            <InputLabel id="source-date-within-label">
              Source date within
            </InputLabel>
            <Select
              labelId="source-date-within-label"
              label="Source date within"
              id="searchPeriod"
              value={searchParams.get('searchPeriod')}
              onChange={(e) => setDatesBasedOnPeriodName(e.target.value)}
            >
              {searchPeriods.map((period, idx) => (
                <MenuItem key={idx} value={period.name}>
                  {period.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {nlpSources?.length > 1 && (
          <Grid item sm={6} md={3}>
            <FormControl
              className={styles.searchPeriodFilter}
              disabled={loading}
            >
              <InputLabel id="source-date-within-label">NLP Source</InputLabel>
              <Select
                labelId="source-date-within-label"
                label="NLP Source"
                id="nlpSource"
                value={searchParams.get('nlpSource')}
                onChange={(e) => updateNlpSource(e.target.value)}
              >
                {nlpSources.map((nlpSource, idx) => (
                  <MenuItem key={idx} value={nlpSource.value}>
                    {nlpSource.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item sm={6} md={3}>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={<RestartAltIcon />}
            onClick={handleResetFilters}
            disabled={loading}
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default HCCFilter;
