import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  QAHccs: {}
};

const qaSlice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    setQAHccs(state, action) {
      state.QAHccs = action.payload;
    },
    deleteIcdCode(state, action) {
      const { hccLabel, icdCodeId } = action.payload;
      console.log('Deleting ICD Code:', hccLabel, icdCodeId);
      if (state.QAHccs[hccLabel]) {
        state.QAHccs[hccLabel] = state.QAHccs[hccLabel].filter(
          (icdCode) => icdCode._id !== icdCodeId
        );
        if (state.QAHccs[hccLabel].length === 0) {
          delete state.QAHccs[hccLabel];
          console.log('Removed HCC Label:', hccLabel);
        } else {
          console.log('Updated QAHccs:', state.QAHccs[hccLabel]);
        }
      } else {
        console.log('HCC Label not found:', hccLabel);
      }
    }
  }
});

export const { setQAHccs, deleteIcdCode } = qaSlice.actions;
export default qaSlice.reducer;
