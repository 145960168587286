// @flow

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import SourceFeedback from './SourceFeedback';
import { useLazyGetQASourceHistoryQuery } from 'api/api';

type QASourceHistoryListProps = {
  factName: String,
  hccId: String,
  patientId: String,
  classes: Object,
  nlpSource: String,
  setPdfViwerSrc: Function
};

const QASourceHistoryList = (props: QASourceHistoryListProps) => {
  const { factName, hccId, patientId, classes, nlpSource, setPdfViwerSrc } =
    props;
  const [sourceHistory, setSourceHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [getQASourceHistory, { isLoading }] = useLazyGetQASourceHistoryQuery();

  const getSourceHistory = async () => {
    setLoading(true);

    try {
      const response = await getQASourceHistory({
        patientId,
        hccId,
        factName
      });

      console.log('[getQASourceHistory] response: ', response.data);

      setSourceHistory(response.data);
    } catch (error) {
      console.log('[getQASourceHistory] error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSourceHistory();
  }, []);

  const getFactValue = (source) => {
    if (
      source.factValue &&
      source.factValue.trim().toLowerCase() === 'undefined'
    ) {
      return '';
    }

    if (source.annotations.Annotation === 'Medication') {
      return source.factValue
        ? `${source.factValue} ${source.unit ?? ''}`
        : 'Supportive Evidence: Yes';
    }

    if (source.annotations.Annotation === 'Diagnostics') {
      return source.factValue
        ? `${source.factValue} ${source.unit ?? ''}`
        : 'Supportive Evidence: Yes';
    }

    return 'Supportive Evidence: Yes';
  };

  return (
    <>
      {loading ? (
        <>
          <CircularProgress style={{ marginTop: 20 }} size={25} />
        </>
      ) : sourceHistory?.length ? (
        <>
          {sourceHistory.map((src, idx) => (
            <div key={`${src._id}-${idx}`} className={classes.sourceCard}>
              {src.date ? (
                <div className={classes.dateWrapper}>
                  <Typography className={classes.date}>{src.date}</Typography>
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start'
                }}
              >
                <div className={classes.factValueWrapper}>
                  <Typography className={classes.factValue}>
                    {getFactValue(src)}
                  </Typography>
                </div>
                {user?.isReveleerTeam && (
                  <Grid
                    item
                    sm={2}
                    md={1}
                    style={{ marginLeft: 'auto', flexShrink: 0 }}
                  >
                    <SourceFeedback
                      userFeedback={src.userFeedback}
                      sourceId={src._id}
                    />
                  </Grid>
                )}
              </div>

              {src.excerpt ? (
                <div className={classes.excerpt}>
                  <FontAwesomeIcon
                    style={{ marginRight: 5, color: 'grey' }}
                    icon={solid('quote-left')}
                  />
                  <Typography>{src.excerpt}</Typography>
                </div>
              ) : null}

              {src.qa ? (
                <div className={classes.annotations}>
                  <TextField
                    size="small"
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                        fontSize: '0.9rem!important',
                        background: 'rgba(51, 122, 94, 0.05)',
                        textAlign: 'left'
                      }
                    }}
                    className={classes.annotation}
                    label={`QA`}
                    value={src.qa}
                    disabled
                  />
                </div>
              ) : null}

              {src.annotations ? (
                <div className={classes.annotations}>
                  {Object.keys(src.annotations).map((annotationName) => (
                    <>
                      {src.annotations[annotationName] ? (
                        <TextField
                          size="small"
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                              fontSize: '0.9rem',
                              background: 'rgba(0, 150, 136, 0.05)',
                              textAlign: 'left'
                            }
                          }}
                          style={{
                            width:
                              src.annotations[annotationName].length * 6 + 70
                          }}
                          className={classes.annotation}
                          label={annotationName}
                          value={src.annotations[annotationName]}
                          disabled
                        />
                      ) : null}
                    </>
                  ))}
                  {src.sourceLocation.section ? (
                    <div className={classes.annotation}>
                      <TextField
                        size="small"
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#000000',
                            fontSize: '0.9rem!important',
                            background: 'rgba(51, 122, 94, 0.05)',
                            textAlign: 'left'
                          },
                          width: src.sourceLocation.section.length * 6 + 70
                        }}
                        className={classes.annotation}
                        label={`Section`}
                        value={src.sourceLocation.section}
                        disabled
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              {src.links.length ? (
                <div style={{ marginTop: '1rem' }}>
                  <div style={{ display: 'inline-flex' }}>
                    {src.links.map((sourceLink, k) =>
                      sourceLink.label === 'PDF' ? (
                        <Button
                          variant="outlined"
                          key={k}
                          className={classes.externalLinkBtn}
                          onClick={() => setPdfViwerSrc(src)}
                        >
                          {sourceLink.label}
                          <ForwardRef>
                            <FontAwesomeIcon
                              icon={solid('external-link')}
                              className={classes.icon}
                            />
                          </ForwardRef>
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          key={k}
                          className={classes.externalLinkBtn}
                          href={sourceLink.url}
                          target="_blank"
                        >
                          {sourceLink.label}
                          <ForwardRef>
                            <FontAwesomeIcon
                              icon={solid('external-link')}
                              className={classes.icon}
                            />
                          </ForwardRef>
                        </Button>
                      )
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default QASourceHistoryList;
